import React from 'react'
import styles from './index.module.css'

const Privacy = () => {
    return(
        <div className={styles.container}>
            <h1 className={styles.title}>《联每户服务协议》</h1>
            <h3>一、服务条款的确认和接纳</h3>
            <div className={styles.describe}>
            联每户物业系统涉及的产品、相关软件的所有权和运作权归上海庆科信息技术有限公司所有，用户一旦注册并勾选同意本服务条款，即表示用户与上海庆科信息技术有限公司已达成协议，自愿接受本服务条款的所有内容和相关使用流程。
            </div>
            <h3>二、用户注册及账户安全性</h3>
            <div className={styles.describe}>
            1、用户可在联每户物业系统通过手机号进行注册。如注册用户满足以下条件可进行房屋认证（房屋认证后可以享受服务见“本协议第四部分的第5-9条”）
            </div>
            <div className={styles.describe}>
            (1) 用户所在小区的物业公司与上海庆科信息技术有限公司已经签订了使用联每户物业系统的合作协议；
            </div>
            <div className={styles.describe}>
            (2) 用户必须是所在小区的业主或租客或商品所有人；
            </div>
            <div className={styles.describe}>
            2、用户一旦注册成功，成为联每户物业系统的用户，后续将使用手机号和验证码的方式进行登录。
            </div>
            <div className={styles.describe}>
            3、用户对用户名和验证码的安全负全部责任，同时对以其用户名进行的所有活动和事件负全责。
            </div>
            <div className={styles.describe}>
            4、用户不得以任何形式擅自转让或授权他人使用自己的联每户物业系统用户名。
            </div>
            <h3>三、用户声明与保证</h3>
            <div className={styles.describe}>
            1、用户承诺其为具有完全民事行为能力的民事主体，具有达成交易履行其义务的能力。
            </div>
            <div className={styles.describe}>
            2、用户有义务在注册时提供自己的真实资料，并保证诸如联系电话等内容的有效性及安全性，保证联每户物业系统工作人员可以通过上述联系方式与用户取得联系。同时，用户也有义务在相关资料实际变更时及时更新有关注册资料。
            </div>
            <div className={styles.describe}>
            3、用户同意其所在小区物业公司及上海庆科信息技术有限公司通过联每户物业系统向其发送信息，包含但不限于：
            </div>
            <div className={styles.describe}>
            (1) 小区的通知、宣传活动、调查表等信息；
            </div>
            <div className={styles.describe}>
            (2) 用户自己的物业管理账单及水电费账单等收费凭证；
            </div>
            <div className={styles.describe}>
            (3) 广告、便民商家信息、团购等商家信息；
            </div>
            <div className={styles.describe}>
            (4) 其他用户向其发送的信息等。
            </div>
            <div className={styles.describe}>
            4、用户通过联每户物业系统发布的内容不得违反国家相关法律制度，包含但不限于如下原则：
            </div>
            <div className={styles.describe}>
            (1) 反对宪法所确定的基本原则的；
            </div>
            <div className={styles.describe}>
            (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            </div>
            <div className={styles.describe}>
            (3) 损害国家荣誉和利益的；
            </div>
            <div className={styles.describe}>
            (4) 煽动民族仇恨、民族歧视，破坏民族团结的；
            </div>
            <div className={styles.describe}>
            (5) 破坏国家宗教政策，宣扬邪教和封建迷信的；
            </div>
            <div className={styles.describe}>
            (6) 散布谣言，扰乱社会秩序，破坏社会稳定的；
            </div>
            <div className={styles.describe}>
            (7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
            </div>
            <div className={styles.describe}>
            (8) 侮辱或者诽谤他人，侵害他人合法权益的；
            </div>
            <div className={styles.describe}>
            (9) 含有法律、行政法规禁止的其他内容的。
            </div>
            <h3>四、 联每户物业系统提供的服务内容</h3>
            <div className={styles.describe}>
            1、用户通过联每户物业系统可获得如下主要服务：
            </div>
            <div className={styles.describe}>
            (1) 在小区论坛同邻居或周边用户进行沟通；
            </div>
            <div className={styles.describe}>
            (2) 在首页广场中的内容发布评论；
            </div>
            <div className={styles.describe}>
            (3) 参与联每户提供的活动或游戏并有机会获得奖励；
            </div>
            <div className={styles.describe}>
            (4) 查看小区周围的便民信息、参加团购、购买商品、预定服务；
            </div>
            <div className={styles.describe}>
            (5) 房屋认证用户可使用手机开门或其他智能硬件设备及应用服务。
            </div>
            <div className={styles.describe}>
            (6) 房屋认证用户可查收其所在小区物业公司发出的通知、账单等信息；
            </div>
            <div className={styles.describe}>
            (7) 房屋认证用户可在线提交反馈意见给物业公司；
            </div>
            <div className={styles.describe}>
            (8) 房屋认证用户可免费使用其所在小区的智能快递柜存件、取件；
            </div>
            <div className={styles.describe}>
            (9) 房屋认证用户可使用免费小区wifi，获取网络访问权限；
            </div>
            <div className={styles.describe}>
            2、联每户物业系统有权随时审核或者删除用户发布的涉嫌违法，或违反社会主义精神文明，或者被联每户物业系统认为不妥当的广告、内容或者应用程序信息。
            </div>
            <h3>五、 服务的终止</h3>
            <div className={styles.describe}>
            在下列情况下，联每户物业系统有权终止向用户提供服务：
            </div>
            <div className={styles.describe}>
            1、在用户违反本服务协议相关规定时，联每户物业系统有权终止向该用户提供服务。如该用户再一次直接或间接或以他人名义注册为用户的，一经发现，联每户物业系统有权直接单方面终止向该用户提供服务；
            </div>
            <div className={styles.describe}>
            2、一旦联每户物业系统发现用户提供的数据或信息中含有虚假内容的，联每户物业系统有权随时终止向该用户提供服务；
            </div>
            <div className={styles.describe}>
            3、本服务条款终止或更新时，用户明示不愿接受新的服务条款的；
            </div>
            <div className={styles.describe}>
            4、其它联每户物业系统认为需终止服务的情况。
            </div>
            <div className={styles.describe}>
            服务终止后，联每户物业系统没有义务为用户保留原账号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方。
            </div>

            <h3>六、 服务的变更、中断</h3>
            <div className={styles.describe}>
            1、鉴于网络服务的特殊性，用户需同意联每户物业系统会变更、中断部分或全部的网络服务，并删除（不再保存）用户在使用服务中提交的任何资料，而无需通知用户，也无需对任何用户或任何第三方承担任何责任。
            </div>
            <div className={styles.describe}>
            2、联每户物业系统需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，联每户物业系统无需为此承担任何责任。
            </div>
            
            <h3>七、 服务条款修改</h3>
            <div className={styles.describe}>
            1、联每户物业系统保留随时修改或中断服务而不需通知用户的权利。
            </div>
            <div className={styles.describe}>
            2、如果不同意联每户物业系统对本服务条款所做的修改，用户有权停止使用网络服务。
            </div>
            <div className={styles.describe}>
            3、如果用户继续使用网络服务，则视为用户接受联每户物业系统对本服务条款所做的修改。
            </div>
            
            <h3>八、 免责与赔偿声明</h3>
            <div className={styles.describe}>
            1、若联每户物业系统已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。
            </div>
            <div className={styles.describe}>
            2、用户明确同意其使用联每户物业系统服务所存在的风险将完全由其自己承担；因其使用联每户物业系统服务而产生的一切后果也由其自己承担。
            </div>
            <div className={styles.describe}>
            3、用户同意保障和维护联每户物业系统及其他用户的利益，由于用户发布内容违法、不真实、不正当、侵犯第三方合法权益，或用户违反本协议项下的任何条款而给上海庆科信息技术有限公司、所在小区物业公司或其他第三人造成损失的，用户同意承担由此造成的全部赔偿责任。
            </div>
          
            <h3>九、 隐私声明</h3>
            <div className={styles.describe}>
            1、适用范围：
            </div>
            <div className={styles.describe}>
            (1)在用户注册联每户物业系统账户时，根据要求提供的个人注册信息；
            </div>
            <div className={styles.describe}>
            (2)在用户使用联每户物业系统服务时，联每户物业系统自动接收并记录的信息或数据。
            </div>
            <div className={styles.describe}>
            2、信息使用：
            </div>
            <div className={styles.describe}>
            (1) 联每户物业系统不会向任何人出售或出借用户的个人信息，除非事先得到用户的许可。
            </div>
            <div className={styles.describe}>
            (2) 联每户物业系统亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人信息。任何用户如从事上述活动，一经发现，联每户物业系统有权立即终止与该用户的服务协议，查封其账号。
            </div>
            <div className={styles.describe}>
            (3)为服务用户的目的，联每户物业系统可能通过使用用户的个人信息，向用户提供服务，包括但不限于向用户发出产品和服务信息，或者与联每户物业系统合作伙伴共享信息以便他们向用户发送有关其产品和服务的信息。
            </div>
            <div className={styles.describe}>
            3、信息披露--用户的个人信息将在下述情况下部分或全部被披露：
            </div>
            <div className={styles.describe}>
            (1)经用户同意，向第三方披露；
            </div>
            <div className={styles.describe}>
            (2)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
            </div>
            <div className={styles.describe}>
            (3)如果用户出现违反中国有关法律或者网站政策的情况，需要向第三方披露；
            </div>
            <div className={styles.describe}>
            (4)为提供用户所要求的产品和服务，而必须和第三方分享用户的个人信息；
            </div>
            <div className={styles.describe}>
            (5)其它联每户物业系统根据法律或者网站政策认为合适的披露；
            </div>
            <div className={styles.describe}>
            以上条款的解释权归上海庆科信息技术有限公司所最终所有。服务网站：www.lianmeihu.com
            </div>
        </div>
    )
}

export default Privacy